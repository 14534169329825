import React, { useEffect, useState } from 'react';
import '../../Page.css';
import { IonButton, IonInput, IonItem, IonLabel, IonLoading, IonRadio, IonRadioGroup } from '@ionic/react';
/* Pullstate Store */
import { PermitStore } from '../../../store/PermitStore';
import firebaseApp, { firestore } from '../../../firebase';
import { UserStore } from '../../../store/UserStore';
import dayjs from 'dayjs';


const Step5 = () => {
    const step = PermitStore.useState(s => s.applicationStep);
    const application = PermitStore.useState(s => s.application); 
    const userDoc = UserStore.useState(s => s.userDoc);
    const [ showBusinessInput, setShowBusinessInput ] = useState(false);
    const [ showLoading, setShowLoading ] = useState(false);

    const createPermit = () => {

        setShowLoading(true);
          firestore.collection('permits').doc(userDoc.lastName+'-'+application.type+'-'+application.startDate).set({
              ...application
          })
          .then(() => {
              firestore.collection('users').doc(firebaseApp.auth().currentUser.uid).collection('permits').doc(userDoc.lastName+'-'+application.type+'-'+application.startDate)
              .set({
              ...application
              })
          })
          .then(() => {
              setShowLoading(false);
              PermitStore.update(s => {
                s.applicationStep = 7
              });
          })
          .catch((error) => {
              console.log(error);
              setShowLoading(false);
              alert('There was an error processing your application. Please try again. If this error perists, please contact support.')
          })
      }
    useEffect(() => {
        if(!showBusinessInput) {
            PermitStore.update(s => {
                s.application = {
                    ...application,
                    businessName: null
                }
            })
        }
    }, [application, showBusinessInput])
    return (
        <div className={ step === 5 ? 'steps' : 'ion-hide'}>
            <IonLoading
                isOpen={showLoading}
                onDidDismiss={() => setShowLoading(false)}
                message={'Please wait...'}
            />
            <h2 className="ion-padding-horizontal onboardingTitles">Please confirm application details</h2>
            <div style={{height: '0px'}} />
            <div className="ion-padding">
                <h2>Applicant: </h2>
                <IonRadioGroup  
                    mode="md"
                    aria-label={'List of burn permits'}
                    value={showBusinessInput} 
                    onIonChange={e => {
                        setShowBusinessInput(e.target.value)
                    }}
                >
                    <IonItem aria-label={'address'}>
                        <IonLabel className="ion-text-wrap">
                            {userDoc.firstName + ' ' + userDoc.lastName}
                        </IonLabel>
                        <IonRadio slot="start" value={false} />
                    </IonItem>
                    <IonItem aria-label={'address'}>
                        <IonLabel className="ion-text-wrap">
                            Apply on behalf of a business instead
                        </IonLabel>
                        <IonRadio slot="start" value={true} />
                    </IonItem>
                </IonRadioGroup>
                {showBusinessInput ? 
                <div className="ion-padding">
                    <IonItem>
                        <IonLabel position="floating">Business Name</IonLabel>
                        <IonInput 
                            placeholder="Please enter the name of the business"
                            onIonChange={e => 
                                PermitStore.update(s => {
                                    s.application = {
                                        ...application,
                                        businessName: e.detail.value
                                    }
                                  })
                                }
                             />
                    </IonItem>
                </div>
                : null }
                <h2 className="onboardingTitles">Type:</h2>
                <p className="">{application.type}</p>
                { application.businessName ? (
                    <>
                        <h2 className="onboardingTitles">Business Name:</h2>
                        <p className="">{application.businessName}</p>
                    </>) : null}
                <h2 className="onboardingTitles">Valid:</h2>
                <p className="">{dayjs(Date.now()).format('MMMM DD, YYYY')} to {dayjs(Date.now() + application.validFor).format('MMMM DD, YYYY')}</p>
                
                <h2 className="onboardingTitles">Address:</h2>
                <p className="">{application.address !== 'other' ? application.address : application.otherAddress}</p>
                <h2 className="onboardingTitles">Price:</h2>
                <p className="">${application.price}</p>
            </div>
                <IonButton
                    className="ion-margin" 
                    shape="round" 
                    expand="block"
                    onClick={() => {
                        application.price > 0? 
                            PermitStore.update(s => {
                                s.applicationStep = 6;
                            })
                        : createPermit()
                    }} 
                >
                    {application.price > 0 ? 'Proceed to Payment' 
                    : 'Submit Permit Application'}
                </IonButton>
        </div>
    );
};

export default Step5;