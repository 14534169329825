import { IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonLoading, IonMenuButton, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import React from 'react';
import './Page.css';
/* Pullstate Store */
import { TownStore } from '../store/TownStore';

import BurnBan from '../components/BurnBan';
import AccountButton from '../components/AccountButton';
import Widgets from '../components/Widgets';
import Footer from '../components/Footer';
import headerIcon from '../assets/images/headerIcon.png';

const Home = () => {
    const townDoc = TownStore.useState(s => s.townDoc);
    const riskLevel = TownStore.useState(s => s.riskLevel);
    const burnBan = TownStore.useState(s => s.burnBan);

    // useEffect(() => {
    //   console.log(townDoc);
    // }, [townDoc])
    
    if (townDoc) {
      return (
        <IonPage mode="ios">
          <IonHeader color="primary">
            <IonToolbar>
              <IonButtons slot="start">
                <IonMenuButton /> <img src={headerIcon} className="headerIcon" alt="FirePermit.Online" />
              </IonButtons>
              
              <IonTitle>{townDoc && townDoc.fullName} - FirePermits.Online</IonTitle>
              <IonButtons slot="end">
                <AccountButton icon={true} color={"primary"} />
              </IonButtons>
            </IonToolbar> 
          </IonHeader>

          <IonContent fullscreen>
            <IonGrid className="contentHolder">
              <IonRow>
                <IonCol>
                  <p className="ion-text-center">
                    <img src={townDoc && townDoc.logo} alt={`${townDoc && townDoc.fullName} Fire Department`}/>
                  </p>
                </IonCol>
              </IonRow>
              {riskLevel === 5 || burnBan ?
              <IonRow>
                <IonCol>
                  <BurnBan />
                </IonCol>
              </IonRow> : null}
              <IonRow>
                <IonCol className="ion-padding-horizontal">
                  <h2 className="ion-text-center headingText">Apply for a Permit</h2>
                  <p className="ion-text-center"><AccountButton /></p>
                </IonCol>
              </IonRow>
              <Widgets />
            </IonGrid>
            <Footer />
          </IonContent>
        </IonPage>
      );
    }
    else {
      return (
        <IonPage mode="ios">
          <IonHeader color="primary">
            <IonToolbar>
              <IonTitle>FirePermit.Online</IonTitle>
            </IonToolbar> 
          </IonHeader>
          <IonContent fullscreen>
            <IonLoading />
          </IonContent>
        </IonPage>
      );
    }
};

export default Home;
