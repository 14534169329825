import { Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import React from 'react';
import fpoLogo from '../assets/images/fpoLogo.png';
// import { useStoreState } from 'pullstate';
// import { TownStore } from '../store/TownStore';

const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#fff',
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
    },
});
// const mapKey = 'AIzaSyCnh3jo3JObQ7fEv8TmJH8TJFJo26tlsdY';
// Create styles
const PermitPDF = (props) => {
    // console.log(props)
    const { permit } = props;
    // const { mapsAPI } = useStoreState(TownStore);
    return (
        <Page size='A4' style={styles.page}>
            <View style={styles.section}>
                <Text style={{ textAlign: 'center', fontFamily: 'Helvetica-Bold', fontSize: 20 }}>
                    <Image src={fpoLogo} alt='firepermit.online' style={{ width: 400 }} />
                </Text>
                <View style={{ height: 25 }} />
                <Text style={{ textAlign: 'center', fontFamily: 'Helvetica-Bold', fontSize: 20 }}>
                    PSFD BURN PERMIT
                </Text>
                <View style={{ height: 15 }} />
                {permit && permit.active === 'Cancelled' ? (
                    <Text
                        style={{
                            color: '#eb445a',
                            fontFamily: 'Helvetica-Bold',
                            fontSize: 15,
                            textAlign: 'center',
                            fontWeight: 'bold',
                        }}
                    >
                        CANCELLED
                    </Text>
                ) : null}
                <Text
                    style={{
                        fontFamily: 'Helvetica-Bold',
                        fontSize: 15,
                        textAlign: 'center',
                        fontWeight: 'bold',
                    }}
                >
                    OPEN AIR FIRE PERMIT
                </Text>
                <Text
                    style={{
                        fontFamily: 'Helvetica-Bold',
                        fontSize: 15,
                        textAlign: 'center',
                        fontWeight: 'bold',
                    }}
                >
                    {permit && dayjs(permit.from).format('MMM DD, YYYY')}
                </Text>
                <View style={{ height: 100 }} />

                <View>
                    <Text style={{ fontSize: 14, marginBottom: 10 }}>
                        <Text style={{ fontFamily: 'Helvetica-Bold' }}>Municipality:</Text>{' '}
                        {permit && permit.type}
                    </Text>
                    <Text style={{ fontSize: 14, marginBottom: 10 }}>
                        <Text style={{ fontFamily: 'Helvetica-Bold' }}>Applicant:</Text>{' '}
                        {permit && permit.firstName} {permit && permit.lastName}{' '}
                    </Text>
                    {permit.active === 'Cancelled' ? null : (
                        <Text style={{ fontSize: 14, marginBottom: 10 }}>
                            <Text style={{ fontFamily: 'Helvetica-Bold' }}>Valid:</Text>{' '}
                            {permit && dayjs(permit.from).format('MMM DD, YYYY')} to{' '}
                            {permit && dayjs(permit.to).format('MMM DD, YYYY')}
                        </Text>
                    )}
                    {permit.active === 'Cancelled' ? (
                        <Text style={{ fontSize: 14, marginBottom: 10 }}>
                            <Text style={{ fontFamily: 'Helvetica-Bold' }}>
                                Cancellation Reason:
                            </Text>{' '}
                            {permit.reason.desc}
                        </Text>
                    ) : null}
                    <Text style={{ fontSize: 14, marginBottom: 10 }}>
                        <Text style={{ fontFamily: 'Helvetica-Bold' }}>Burn Location:</Text>{' '}
                        {permit && permit.otherAddress ? permit.otherAddress : permit.address}
                    </Text>

                    {/* <Image style={{width: '100%', height: permit.active === 'Cancelled' ? '235px' : 'auto', margin: '0 auto', marginBottom: 15}} src={`https://maps.googleapis.com/maps/api/staticmap?center=${permit.latitude},${permit.longitude}&zoom=17&size=600x300&maptype=satellite&markers=color:red%7C${permit.latitude},${permit.longitude}&key=${mapKey}`} /> */}

                    {/* <Text style={{fontSize: 14, marginBottom: 10}}>{permit && permit.latitude}</Text>
          <Text style={{fontSize: 14, marginBottom: 10}}>{permit && permit.longitude}</Text> */}
                </View>
                <View style={{ height: 50 }} />
                <View>
                    {permit.active === 'Cancelled' ? null : (
                        <Text style={{ fontSize: 12, lineHeight: 1.4, marginBottom: 7.5 }}>
                            This {permit.type} is issued by the Fire Chief on the condition of full
                            compliance with the conditions and regulations prescribed by the By-Law.
                        </Text>
                    )}
                    <Text style={{ fontSize: 12, lineHeight: 1.4, marginBottom: 7.5 }}>
                        The burn location specified in this permit may be subject to inspection. The
                        Fire Chief may suspend or revoke, without prior notice, any issued permit
                        where the applicant is not able to meet or has contravened any condition of
                        the permit.
                    </Text>
                </View>
                <View
                    style={{
                        borderColor: '#000',
                        borderTopWidth: 2.5,
                        width: '100%',
                        margin: '10px 0',
                        position: 'absolute',
                        bottom: 0,
                        padding: 10,
                    }}
                >
                    <Text style={{ textAlign: 'center', marginBottom: 5, fontSize: 14 }}>
                        {permit.type} -{' '}
                        <Image src={fpoLogo} style={{ width: 150, margin: '0 auto' }} />
                    </Text>
                    <Text style={{ textAlign: 'center', fontSize: '10' }}>
                        Authentication: {permit && permit.id}
                    </Text>
                </View>
            </View>
        </Page>
    );
};

export default PermitPDF;
